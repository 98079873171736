/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui'
import {
  StytchLogin,
  StytchProvider,
  StytchPasswordReset,
} from '@stytch/nextjs'
import { Products, StytchLoginConfig, OAuthProviders } from '@stytch/vanilla-js'
import { createStytchUIClient } from '@stytch/nextjs/ui'
import { useMemo } from 'react'

const StytchLoginForm: React.FC = () => {
  const baseUrl =
    typeof window !== 'undefined' ? window.location.origin : undefined

  const stytchProps = useMemo<StytchLoginConfig | null>(() => {
    if (!baseUrl) {
      return null
    }

    return {
      products: [Products.emailMagicLinks, Products.oauth, Products.passwords],
      emailMagicLinksOptions: {
        loginRedirectURL: `${baseUrl}/authenticate`,
        loginExpirationMinutes: 30,
        signupRedirectURL: `${baseUrl}/authenticate`,
        signupExpirationMinutes: 30,
        createUserAsPending: false,
      },
      // these won't work until you have set them up in Stytch
      oauthOptions: {
        loginRedirectURL: `${baseUrl}/authenticate`,
        signupRedirectURL: `${baseUrl}/authenticate`,
        providers: [{ type: OAuthProviders.Google }],
      },
      passwordOptions: {
        loginExpirationMinutes: 30,
        loginRedirectURL: `${baseUrl}/authenticate`,
        resetPasswordExpirationMinutes: 30,
        resetPasswordRedirectURL: `${baseUrl}/authenticate`,
      },
    }
  }, [])

  if (!stytchProps) {
    return null
  }

  const passwordResetToken = new URLSearchParams(window.location.search).get(
    'token'
  )

  const resetConfig = {
    passwordOptions: {
      loginExpirationMinutes: 30,
      loginRedirectURL: `${baseUrl}/authenticate`,
      resetPasswordExpirationMinutes: 30,
      resetPasswordRedirectURL: `${baseUrl}/authenticate`,
    },
    products: [Products.passwords],
  }

  return (
    <StytchProvider
      stytch={createStytchUIClient(process.env.NEXT_PUBLIC_STYTCH_API_KEY)}
    >
      <Flex sx={{ flexDirection: 'column', width: '400px', margin: '0 auto' }}>
        {!passwordResetToken ? (
          <StytchLogin
            config={stytchProps}
            styles={{
              hideHeaderText: true,
              container: {
                borderColor: 'transparent',
                width: '100%',
              },
              buttons: {
                primary: {
                  backgroundColor: 'var(--green-one-off)',
                  textColor: 'var(--black-one-off)',
                },
              },
            }}
            callbacks={{
              onEvent: (event) => {
                if (event.data.status_code === 200) {
                  setTimeout(() => {
                    window.location.reload()
                  }, 500)
                }
              },
              onError: (error) => console.log(error),
            }}
          />
        ) : (
          <StytchPasswordReset
            config={resetConfig}
            passwordResetToken={passwordResetToken}
          />
        )}
      </Flex>
    </StytchProvider>
  )
}

export default StytchLoginForm
