import React, { useEffect } from 'react'
import Metadata from '~/components/Metadata'
import AccountLoginPage from '~/components/Account/Login/Page'
import nextContentWrapper from 'graphql-cms/nextContentWrapper'
import type { InferNextPage } from '~/types'
import { getAnnouncementBar } from 'graphql-cms/queries'
import { useRouter } from 'next/router'
import { useAuth } from '@chordcommerce/react-autonomy'

export const getStaticProps = nextContentWrapper.getStaticProps(async () => {
  return {
    props: {
      announcementBarConfig: await getAnnouncementBar(),
    },
  }
})

const Login: InferNextPage<typeof getStaticProps> = () => {
  const { isLoggedIn } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (isLoggedIn) router.push('/account')
  }, [isLoggedIn, router])

  return (
    <>
      <Metadata title={'Login'} />
      <AccountLoginPage />
    </>
  )
}

export default Login
