/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, Heading } from 'theme-ui'
import StytchLoginForm from '~/components/Auth/StytchLoginForm'
import { useTranslate } from '~/hooks'

const AccountLoginPage: React.FC = () => {
  const translate = useTranslate()

  return (
    <Box
      sx={{
        margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
        maxWidth: '600px',
      }}
    >
      <Heading
        as="h1"
        variant="h1"
        sx={{
          textAlign: 'center',
          marginTop: ['16px', null, '36px'],
          marginBottom: ['8px', null, '32px'],
        }}
      >
        {translate('login.page_title')}
      </Heading>

      <Box
        sx={{
          margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
          maxWidth: '600px',
        }}
      >
        <StytchLoginForm />
      </Box>
    </Box>
  )
}

export default AccountLoginPage
